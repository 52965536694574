<template>
	<div class="selection">
		<van-popup v-model="show" position="bottom" class="popCancer">
			<!-- 癌种选择组件 -->
			<div class="cancer" v-show="poptype==1">
				<div class="cancer_header">
					<div>癌种</div>
					<div @click="cancal">取消</div>
				</div>
				<div class="cancer_content">
					<!-- <div class="itemInput">
						<input type="text" placeholder="没有找到我的疾病，点击输入" v-model="otherName">
						<div class="determine" @click="otherInput">确定</div>
					</div> -->
					<div v-for="(item,index) in data" :key="index" @click="finallyClose(index,item)" :class="[tempIndex==index?'item_active':'','item']">
						{{item.illName}}
					</div>
				</div>
			</div>

			<!-- 第一次就诊医院选择组件 -->
			<div class="district" v-show="poptype==2">
				<div class="district_header">
					<div class="district_title">
						<span @click="topTitle(0)">{{headerTitle[0]}}</span>
						<span @click="topTitle(1)" v-if="headerTitle[1]!=''">{{headerTitle[1]}}</span>
						<span @click="topTitle(2)" v-if="headerTitle[2]!=''">{{headerTitle[2]}}</span>
					</div>
					<div @click="cancal">取消</div>
				</div>
				<div class="district_content">
					<div class="itemInput" v-if="grade==2">
						<input type="text" placeholder="没有我的医院，点击输入" v-model="otherName">
						<div class="determine" @click="otherInput">确定</div>
					</div>
					<div v-for="(item,index) in province" :key="index" @click="selProvince(item)" class="item" v-if="grade==0">
						{{item.name}}
					</div>
					<div v-for="(item,index) in city" :key="index" @click="selCity(item)" class="item" v-if="grade==1">
						{{item.name}}
					</div>
					<div v-for="(item,index) in district" :key="index" @click="finallyClose(index,item)" :class="[tempIndex==index?'item_active':'','item']"
					 v-if="grade==2">
						{{item.name}}
					</div>
				</div>
			</div>
			<!-- 所在地区选择组件 -->
			<div class="live" v-show="poptype==3">
				<div class="live_header">
					<div>
						<span @click="topTitle(0)">{{headerTitle[0]}}</span>
						<span @click="topTitle(1)" v-if="headerTitle[1]!=''">{{headerTitle[1]}}</span>
					</div>
					<div @click="cancal">取消</div>
				</div>
				<div class="live_content">
					<div v-for="(item,index) in province" :key="index" @click="selProvince(item)" class="item" v-if="grade==0">
						{{item.name}}
					</div>
					<div v-for="(item,index) in city" :key="index" @click="finallyClose(index,item)" :class="[tempIndex==index?'item_active':'','item']"
					 v-if="grade==1">
						{{item.name}}
					</div>

				</div>
			</div>
			<!-- 出生日期选择组件 -->
			<div class="birth" v-show="poptype==4">
				<van-datetime-picker @cancel="cancal" v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate" @confirm="finallyClose" :formatter="formatter" />
			</div>
			<!-- 疾病分期组件 -->
			<div class="diseaseStages" v-show="poptype==5">
				<div class="diseaseStages_header">
					<div>疾病分期</div>
					<div @click="cancal">取消</div>
				</div>
				<div class="diseaseStages_content">
					<div v-for="(item,index) in stage" :key="index" @click="finallyClose(index,item)" :class="[tempIndex==index?'item_active':'','item']">
						{{item.stages}} 
					</div>
				</div>
			</div>
			<!-- 关系列表组件 -->
			<div class="relation" v-show="poptype==6">
				<div class="relation_header">
					<div>关系</div>
					<div @click="cancal">取消</div>
				</div>
				<div class="relation_content">
					<div v-for="(item,index) in relation" :key="index" @click="finallyClose(index,item)" :class="[tempIndex==index?'item_active':'','item']">
						{{item.value}}
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import {
		Popup,
		Toast,
		DatetimePicker,
	} from 'vant';
	import patientApi from '@/api/patient/patientApi.js'
	export default {
		components: {
			[Popup.name]: Popup,
			[Toast.name]: Toast,
			[DatetimePicker.name]: DatetimePicker
		},
		props: {
			//如果是弹出框，则判断是哪种弹出框
			//1为癌种弹出框 2就诊医院弹出框 3为所在地区弹出框 
			poptype: {
				type: String,
				default: '0'
			},
		},
		data() {
			return {
				/* 初始化数据是否完成 */
				isOk:false,
				/* 癌种弹出相关变量 */
				data: [],
				show: false,
				otherName: '', //用户手动输入的临时变量
				tempIndex: -1, //临时索引用来标记最后选中的那一个

				/* 地区弹出相关变量 */
				grade: 0, //0级标识省份 1级标识市 2级最小
				headerTitle: ['中国', '', ''],
				ids: ['', '', ''], //保存省市区的id
				names: ['', '', ''], //保存省市区的名称
				province: [], //省份数据
				city: [], //市的数据
				district: [], //区的数据

				/* 出生日期选择组件变量 */
				currentDate: new Date(),
				minDate:new Date('1940/01/01'),
				maxDate:new Date(),
				
				/* 疾病分期 */
				stage:[],
				
				
				/* 我和患者关系 */
				relation:[]
			}
		},
		methods: {
			//编辑下赋值弹框新的值
			setNewValue(type,value){
				let timer=setInterval(()=>{
					if(this.isOk){
						if(type=='illType'){
							for(let i=0;i<this.data.length;i++){
								if(this.data[i].illName==value){
									this.tempIndex=i;
									break;
								}
							}
						}
						if(type=='carcuStage'){
							for(let i=0;i<this.stage.length;i++){
								if(this.stage[i].id==value){
									this.tempIndex=i;
									break;
								}
							}
						}
						if(type=="relate"){
							for(let i=0;i<this.relation.length;i++){
								if(this.relation[i].key==value){
									this.tempIndex=i;
									break;
								} 
							}
						}

						window.clearInterval(timer)
					}
				},0)
				
			},
			
			//控制弹框
			cancal() {
				this.show = false;
			},
			//获取不同弹出框列表信息的初始化
			getList() {
				if (this.poptype == 1) {
					patientApi.diseasList().then((res) => {
						this.data = res.data;
						this.isOk=true;
					})
				} else if (this.poptype == 2) {
					this.getProvince()
				} else if (this.poptype == 3) {
					this.getProvince()
				}else if (this.poptype == 6) {
					//患者关系列表
					patientApi.enumsList().then(rs=>{
						this.relation=rs.data.identityList;
						this.isOk=true;
						console.log(this.relation)
					})
				}
			},
			//获取所有省份
			getProvince() {
				patientApi.region({pid:0}).then(rs=>{
					this.province=rs.data;
					this.isOk=true;
				})
				
			},

			//点击中国的处理逻辑
			topTitle(status) {
				if (status < this.grade) {
					if (this.grade - status == 1) {
						this.headerTitle[this.grade] = ''
					} else if (this.grade - status == 2) {
						this.headerTitle[this.grade] = ''
						this.headerTitle[this.grade - 1] = ''
					}
					this.tempIndex=-1;//取消标识
					this.grade = status;
					this.headerTitle[0] = '中国'
				} else {
					this.grade = status;
				}

			},
			//点击省份的处理逻辑(就诊医院组件和地区选择组件共用)
			selProvince(item) {
				let provinceId = item.id;
				let provinceName = item.name;
				this.headerTitle[0]="中国》"
				this.headerTitle[1] = provinceName + "》";
				this.ids[0] = provinceId;
				this.names[0] = provinceName;
				//请求获取当前省份下所有的市级单位
				patientApi.region({pid:provinceId}).then(rs=>{
					console.log(rs)
					this.city=rs.data;
					this.grade = 1;
				})
				
			},
			//点击市级的处理逻辑(就诊医院组件)
			selCity(item) {
				let cityId = item.id;
				let cityName = item.name;
				this.headerTitle[2] = cityName;
				let data={
					provinceId:this.ids[0],
					cityId:cityId,
				}
				patientApi.hospital(data).then(rs=>{
					console.log(rs)
					this.district=rs.data;
					this.grade = 2;
				})
				
			},
			//点击市级的处理逻辑(地区选择组件)
			selCity1(item) {
				let cityId = item.id;
				let cityName = item.name;
				this.grade = 2;
				this.ids[1] = cityId;
				this.names[1] = cityName;
			},
			formatter(type, value) {
				if (type === 'year') {
					return `${value}年`;
				} else if (type === 'month') {
					return `${value}月`
				} else {
					return `${value}日`
				}
				return value;
			},
			timeFormat(time) { // 时间格式化 2019-09-08
				let year = time.getFullYear();
				let month = time.getMonth() + 1;
				let day = time.getDate();
				return year + '/' + month + '/' + day
			},
			//根据疾病id请求分期列表
			setCarcuStage(illId){
				patientApi.stagesList({illId:illId}).then(rs=>{
					if(rs.data.length==0){
						this.$emit("setHidden",false);
					}else if(rs.data.length>0){
						this.$emit("setHidden",true);
						this.stage=rs.data;
						this.isOk=true
					}
					
				})
			},

			//选择最后一个关闭弹框后的操作
			finallyClose(index, item) {
				//癌种弹出框
				if (this.poptype == 1) {
					//不同疾病有不同的分期
					this.tempIndex = index;
					this.$emit('diseaseClick',item.id);
					this.$emit('setName', item.illName,item.id);
					this.show = false;
				} else if (this.poptype == 2) {
					//就诊医院弹出框
					let districId = item.id;
					let districName = item.name;
					this.ids[2] = districId;
					this.names[2] = districName;
					//收集到医院名称以及医院id提交父组件
					this.tempIndex = index;
					this.$emit('setName', this.names[2], this.ids[2]);
					this.show = false;
				} else if (this.poptype == 3) {
					//地区弹出框
					let cityId = item.id;
					let cityName = item.name;
					this.grade = 1;
					this.ids[1] = cityId;
					this.names[1] = cityName;
					//收集到省市以及省市id 提交父组件
					let names = this.names.join(''); //**省**市
					this.tempIndex = index;
					this.$emit('setName', names, null, [this.ids[0], this.ids[1]]);
					this.show = false;
				} else if (this.poptype == 4) {
					//出生日期弹出框
					let timeValue = this.timeFormat(this.currentDate);
					this.$emit('setName', timeValue);
					this.show = false;				
				}else if (this.poptype == 5) {
					//疾病分期弹出框
					this.tempIndex = index;
					this.$emit('setName', item.stages,item.id);
					this.show = false;				
				}else{
					//患者关系弹出框
					this.tempIndex = index;
					this.$emit('setName', item.value,item.key);
					this.show = false;	
				}
			},
			//用户手动输入癌种名称
			otherInput() {
				//选择的是癌种下的输入
				if (this.poptype == 1) {
					if (this.otherName == '') {
						Toast({
							message: '请输入疾病名称',
							position: 'bottom'
						});
						return;
					} else {
						this.$emit('setName', this.otherName);
						this.show = false;
					}
				} else if (this.poptype == 2) {
					//选择是就诊医院下的输入
					if (this.otherName == '') {
						Toast({
							message: '请输入医院名称',
							position: 'bottom'
						});
						return;
					} else {
						this.$emit('setName', this.otherName);
						this.show = false;
					}
				}
			},
		},
		created() {
			this.getList()
		}

	}
</script>

<style scoped="scoped" lang="less">
	.selection {
		width: 100%;

		.popCancer {
			overflow: hidden;
			height: auto;
			.relation {
				width: 100%;
				height: auto;
			
				.relation_header {
					height: 47px;
					background: rgba(238, 238, 238, 1);
					padding-left: 15px;
					padding-right: 15px;
					box-sizing: border-box;
			
					div {
						// background: red;
						width: 50%;
						height: 47px;
						display: inline-block;
						font-size: 17px;
			
						color: rgba(49, 49, 52, 1);
						vertical-align: text-top;
						line-height: 47px;
			
						&:first-child {
							font-weight: 600;
							text-align: left;
						}
			
						&:last-child {
							text-align: right;
						}
					}
				}
			
				.relation_content {
					width: 100%;
					height: 300px;
					overflow-y: scroll;
			
					.itemInput {
						font-size: 16px;
						font-weight: 400;
						color: rgba(90, 90, 93, 1);
						border-bottom: 1px solid #ACABAD;
						box-sizing: border-box;
						.determine {
							display: inline-block;
							width: 44px;
							height: 25px;
							background: rgba(0, 96, 194, 1);
							border-radius: 2px;
							font-size: 15px;
			
							font-weight: 500;
							color: rgba(255, 255, 255, 1);
							line-height: 25px;
							text-align: center;
							margin-left: 15px;
						}
			
					}
					.item {
						font-size: 16px;
						font-weight: 400;
						color: rgba(90, 90, 93, 1);
						border-bottom: 1px solid #ACABAD;
						padding: 13px;
						box-sizing: border-box;
					}
					.item_active {
						color: #0060C2;
					}
				}
			}
			
			.diseaseStages {
				width: 100%;
				height: auto;
			
				.diseaseStages_header {
					height: 47px;
					background: rgba(238, 238, 238, 1);
					padding-left: 15px;
					padding-right: 15px;
					box-sizing: border-box;
			
					div {
						// background: red;
						width: 50%;
						height: 47px;
						display: inline-block;
						font-size: 17px;
			
						color: rgba(49, 49, 52, 1);
						vertical-align: text-top;
						line-height: 47px;
			
						&:first-child {
							font-weight: 600;
							text-align: left;
						}
			
						&:last-child {
							text-align: right;
						}
					}
				}
			
				.diseaseStages_content {
					width: 100%;
					height: 300px;
					overflow-y: scroll;
			
					.itemInput {
						font-size: 16px;
						font-weight: 400;
						color: rgba(90, 90, 93, 1);
						border-bottom: 1px solid #ACABAD;
						box-sizing: border-box;
						.determine {
							display: inline-block;
							width: 44px;
							height: 25px;
							background: rgba(0, 96, 194, 1);
							border-radius: 2px;
							font-size: 15px;
			
							font-weight: 500;
							color: rgba(255, 255, 255, 1);
							line-height: 25px;
							text-align: center;
							margin-left: 15px;
						}
			
					}
					.item {
						font-size: 16px;
						font-weight: 400;
						color: rgba(90, 90, 93, 1);
						border-bottom: 1px solid #ACABAD;
						padding: 13px;
						box-sizing: border-box;
					}
					.item_active {
						color: #0060C2;
					}
				}
			}
			
			
			
			.live {
				width: 100%;
				height: auto;

				.live_header {
					height: 47px;
					background: rgba(238, 238, 238, 1);
					padding-left: 15px;
					padding-right: 15px;
					box-sizing: border-box;

					div {
						// background: red;
						width: 50%;
						height: 47px;
						display: inline-block;
						font-size: 17px;

						color: rgba(49, 49, 52, 1);
						vertical-align: text-top;
						line-height: 47px;

						&:first-child {
							font-weight: 600;
							text-align: left;
						}

						&:last-child {
							text-align: right;
						}
					}
				}

				.live_content {
					width: 100%;
					height: 300px;
					overflow-y: scroll;

					.itemInput {
						font-size: 16px;
						font-weight: 400;
						color: rgba(90, 90, 93, 1);
						border-bottom: 1px solid #ACABAD;
						box-sizing: border-box;
						.determine {
							display: inline-block;
							width: 44px;
							height: 25px;
							background: rgba(0, 96, 194, 1);
							border-radius: 2px;
							font-size: 15px;

							font-weight: 500;
							color: rgba(255, 255, 255, 1);
							line-height: 25px;
							text-align: center;
							margin-left: 15px;
						}

					}
					.item {
						font-size: 16px;
						font-weight: 400;
						color: rgba(90, 90, 93, 1);
						border-bottom: 1px solid #ACABAD;
						padding: 13px;
						box-sizing: border-box;
					}
					.item_active {
						color: #0060C2;
					}
				}
			}
			/*  */
			.district {
				width: 100%;
				height: auto;
				.district_header {
					height: 47px;
					background: rgba(238, 238, 238, 1);
					padding-left: 15px;
					padding-right: 15px;
					box-sizing: border-box;				
					div {					
						height: 47px;
						display: inline-block;
						font-size: 17px;
						color: rgba(49, 49, 52, 1);
						vertical-align: text-top;
						line-height: 47px;
						&:first-child {
							width: 80%;
							font-weight: 600;
							text-align: left;
						}
						&:last-child {
							width: 20%;
							text-align: right;
						}
					}
				}
				.district_content {
					width: 100%;
					height: 300px;
					overflow-y: scroll;
					.itemInput {
						font-size: 16px;
						font-weight: 400;
						color: rgba(90, 90, 93, 1);
						border-bottom: 1px solid #ACABAD;
						box-sizing: border-box;
						padding: 5px;
						input::-webkit-input-placeholder {
							color: #0060C2;
							text-align: left;

						}

						input:-moz-placeholder {
							color: #0060C2;
							text-align: left;
						}

						input::-moz-placeholder {
							color: #0060C2;
							text-align: left;
						}

						input:-ms-input-placeholder {
							color: #0060C2;
							text-align: left;
						}

						input {
							display: inline-block;
							height: 38px;
							width: 80%;
							padding-left: 15px;
							font-size: 16px;
							box-sizing: border-box;
							border: none;
							outline: none;
							color: #0060C2;
							// background: red;
						}

						.determine {
							display: inline-block;
							width: 44px;
							height: 25px;
							background: rgba(0, 96, 194, 1);
							border-radius: 2px;
							font-size: 15px;

							font-weight: 500;
							color: rgba(255, 255, 255, 1);
							line-height: 25px;
							text-align: center;
							margin-left: 15px;
						}

					}

					.item {
						font-size: 16px;
						font-weight: 400;
						color: rgba(90, 90, 93, 1);
						border-bottom: 1px solid #ACABAD;
						padding: 13px;
						box-sizing: border-box;
					}

					.item_active {
						color: #0060C2;
					}
				}

			}

			.cancer {
				width: 100%;
				height: auto;

				.cancer_header {
					height: 47px;
					background: rgba(238, 238, 238, 1);
					padding-left: 15px;
					padding-right: 15px;
					box-sizing: border-box;

					div {
						// background: red;
						width: 50%;
						height: 47px;
						display: inline-block;
						font-size: 17px;

						color: rgba(49, 49, 52, 1);
						vertical-align: text-top;
						line-height: 47px;

						&:first-child {
							font-weight: 600;
							text-align: left;
						}

						&:last-child {
							text-align: right;
						}
					}
				}

				.cancer_content {
					width: 100%;
					height: 300px;
					overflow-y: scroll;

					.itemInput {
						font-size: 16px;
						font-weight: 400;
						color: rgba(90, 90, 93, 1);
						border-bottom: 1px solid #ACABAD;
						box-sizing: border-box;
						padding: 5px;
						
						
						input::-webkit-input-placeholder {
							color: #0060C2;
							text-align: left;

						}

						input:-moz-placeholder {
							color: #0060C2;
							text-align: left;
						}

						input::-moz-placeholder {
							color: #0060C2;
							text-align: left;
						}

						input:-ms-input-placeholder {
							color: #0060C2;
							text-align: left;
						}

						input {
							display: inline-block;
							height: 38px;
							width: 80%;
							padding-left: 15px;
							font-size: 16px;
							box-sizing: border-box;
							border: none;
							outline: none;
							color: #0060C2;
							
						}

						.determine {
							display: inline-block;
							width: 44px;
							height: 25px;
							background: rgba(0, 96, 194, 1);
							border-radius: 2px;
							font-size: 15px;

							font-weight: 500;
							color: rgba(255, 255, 255, 1);
							line-height: 25px;
							text-align: center;
							margin-left: 15px;
							
						}

					}

					.item {
						font-size: 16px;
						font-weight: 400;
						color: rgba(90, 90, 93, 1);
						border-bottom: 1px solid #ACABAD;
						padding: 13px;
						box-sizing: border-box;
					}

					.item_active {
						color: #0060C2;
					}
				}
			}
		}
	}
</style>
