<template>
	<div class="field" v-show="hidden">
		<selection ref="selection" :poptype="poptype" v-if="type==3" @setName="setName" @setHidden="setHidden"  @diseaseClick="diseaseClick"></selection>
		<div class="field_container" :style="isEditText?'height:auto':'height:46px'">
			<div class="field_title">
				{{title}}
			</div>
			<div class="field_content" v-if="type==1">
				<input type="text" :placeholder="placeholder" v-model="tempName">
			</div>
			<div class="field_content1" v-if="type==2">
				<div @click="changeBtn(1)" :class="[option==1&&start?'active':'','select']">{{first}}</div>
				<div @click="changeBtn(2)" :class="[option==2&&start?'active':'','select']">{{second}}</div>
			</div>
			<div class="field_content3" v-if="type==3">
				<div class="iconSelect" @click="popSel"><span v-if="tempName==''">请选择</span><span style="color: #313134;" v-else>{{tempName}}</span>
					<van-icon name="arrow" size="15" color="#59595C" style="vertical-align: middle;" />
				</div>

			</div>
			<div class="textEdie" v-if="isEditText&&option==1&&start">
				<textarea name="" id="" rows="5" :placeholder="areaTips" v-model="tempName"></textarea>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		Icon,
		Field,	
	} from 'vant';
	import patientApi from '@/api/patient/patientApi.js'
	import selection from '@/components/patient/selection';
	export default {
		components: {
			vanIcon: Icon,
			vanField: Field,
			selection:selection,
			
		},
		props: {
			//标题
			title: {
				type: String,
				default: ''
			},
			//文本框的提示文字
			placeholder: {
				type: String,
				default: '请输入内容'
			},
			//组件类型1输入框  2选择框(是/否) 3.弹出框
			type: {
				type: String,
				default: '1'
			},
			//如果是弹出框，则判断是哪种弹出框
			//1为癌种弹出框 2为所在地区弹出框 3位就诊医院弹出框
			poptype: {
				type: String,
				default: '0'
			},
			//如果是选择框，则判断下面是否需要文本域
			isEditText: {
				type: Boolean,
				default: false
			},
			//文本域的提示文字
			areaTips: {
				type: String,
				default: ''
			},
			//选择框的文字如是/否  男/女
			first: {
				type: String,
				default: '有'
			},
			second: {
				type: String,
				default: '否'
			},
		
		},
		data() {
			return {
				hidden:true, 
				start:false,
				option: -1, //1男 2女 3不选
				tempName:'',//名称
				tempId:'',//记录临时id 如医院id 关系key
				city:[],//记录省市id
				
			}
		},
		methods: {
			setHidden(status){
				this.hidden=status;
			},
			//编辑下设置疾病
			setValue(type,value,id=null,content=null){
				if(type=='illType'){
					this.tempName=value;
					this.tempId = id
					this.$refs.selection.setNewValue('illType',value);
				} 
				else if(type=='carcuStage'){
					this.tempId = value
					this.tempName=content;
					this.$refs.selection.setNewValue('carcuStage',value);
					this.$refs.selection.setCarcuStage(id);
				}
				else if(type=="isOperate"){
					if(value==-1){
						this.start=false;
					}else{
						this.start=true;
						if(value==0){
							this.option=2
						}else if(value==1){
							this.option=1
						}
					}
				}
				else if(type=="isChemothe"||type=="isTarget"||type=="immuno"||type=='radio'||type=='isOtherTreatment'){
					if(value==-1){
						this.start=false;
					}else{
						this.start=true;
						if(value==0){
							this.option=2
						}else if(value==1){
							this.option=1
						}
					}
					if(content!=null){
						this.tempName=content;
					}					
				}
				else if(type=='birthday'){
					this.$refs.selection.currentDate=new Date(value);
					this.tempName=value
				}
				else if(type=="sex"){
					if(value==-1){
						this.start=false;
					}else{
						this.start=true;
						if(value==1){
							this.option=1
						}else if(value==2){
							this.option=2
						}
					}
				}
				else if(type=="name"){
					this.tempName=value
				}
				else if(type=="relate"){
					this.tempName=value;
					this.$refs.selection.setNewValue('relate',id);
				}
			},
		
			diseaseClick(illId){
				// alert("我是field，我被点击了")
				this.$emit('diseaseClick',illId);
			},
			//设置疾病分期列表
			setCarcuStage(illId){
				this.$refs.selection.setCarcuStage(illId)
			},
			changeBtn(status) {
				this.start=true;
				if (status == 1 && (this.option == 2||this.option ==-1)) {
					this.option = 1
				} else if (status == 2 && (this.option == 1||this.option ==-1)) {
					this.option = 2
				}
			},
			popSel(){
				this.$refs.selection.show=true
			},
			//子组件回调父组件方法设置新的名词
			setName(name,tempId=null,city=[]){
				this.tempName=name;
				this.tempId=tempId
				this.city=city
				console.log(this.city)
			},
			//获取是与否/男或女
			getStatus(){
				if(this.first=='有'||this.first=='否'){
					if(this.option==1){
						return 1;
					}else if(this.option==2){
						return 0;
					}else{
						return -1;
					}
				}else if(this.first=='男'||this.first=='女'){
					if(this.option==1){
						return 1;
					}else if(this.option==2){
						return 2;
					}else{
						return -1; 
					}
				}
			},
		},
		
	}
</script>

<style lang="less" scoped="scoped">
	input::-webkit-input-placeholder {
		color: #D5D5D5;
		text-align: right;
	}

	textarea::-webkit-input-placeholder {
		/* WebKit browsers */
		color: #D5D5D5;
		text-align: left;
	}


	input:-moz-placeholder {
		color: #D5D5D5;
		text-align: right;
	}

	textarea:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #D5D5D5;
		text-align: left;
	}

	input::-moz-placeholder {
		color: #D5D5D5;
		text-align: right;
	}

	textarea::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #D5D5D5;
		text-align: left;
	}

	input:-ms-input-placeholder {
		color: #D5D5D5;
		text-align: right;
	}

	textarea:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #D5D5D5;
		text-align: left;
	}

	.field{
		width: 100%;
		background:rgba(255,255,255,1);
		height: auto;
		.field_container {
			width: 92%;
			margin: 0 auto;
			box-sizing: border-box;
			border-bottom: 1px solid #E7E8ED;

			.textEdie {
				width: 100%;
				height: auto;
				text-align: left;

				textarea {
					width: 97%;
					height: 121px;
					font-size: 14px;
					font-weight: 400;
					// color: rgba(172, 171, 173, 1);
					line-height: 14px;
					border: 1px solid #D5D5D5;
					padding: 10px;
					box-sizing: border-box;
				}
			}

			.field_title {
				vertical-align: text-top;
				width: 40%;
				height: 46px;
				line-height: 46px;
				text-align: left;
				display: inline-block;
				font-size: 16px;
				font-weight: 400;
				color: #2A2A2E;
			}

			.field_content {
				width: 60%;
				height: 46px;
				vertical-align: text-top;
				display: inline-block;
				// background: #07C160;
				text-align: right;
				padding-right: 15px;
				box-sizing: border-box;

				input {
					vertical-align: text-top;
					padding: 0;
					width: 100%;
					height: 42px;
					border: none;
					outline: none;
					font-size: 15px;
					text-align: right;
					
				}

			}

			.field_content1 {
				width: 60%;
				height: 46px;
				vertical-align: text-top;
				display: inline-block;
				text-align: right;
				box-sizing: border-box;

				.select {
					display: inline-block;
					width: 66px;
					height: 27px;
					line-height: 27px;
					background: rgba(247, 247, 247, 1);
					font-size: 16px;
					font-weight: 400;
					color: rgba(42, 42, 46, 1);
					text-align: center;
					margin-top: 10px;
					vertical-align: text-top;
					margin-right: 15px;
				}

				.active {
					background: #0060C2;
					transition: all 0.5s ease;
					color: #FFFFFF;
				}
			}

			.field_content3 {
				width: 60%;
				height: auto;
				vertical-align: text-top;
				display: inline-block;
				// background: #07C160;
				text-align: right;
				// padding-right: 20px;
				box-sizing: border-box;

				.iconSelect {
					text-align: right;
					font-size: 15px;
					font-weight: 400;
					color: rgba(213, 213, 213, 1);
					line-height: 46px;
				}


			}
		}

	}
</style>
