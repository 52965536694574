<template>
	<div class="createPatient">
		<div class="necessary">
			<div class="necessary_title">患者被诊断的疾病是什么？</div>
			<field title="疾病" type="3" poptype="1" ref="illType" @diseaseClick="diseaseClick"></field>
			<!-- <field title="分期" type="3" poptype="5" ref="carcuStage"></field> -->
		</div>
		<!-- <div class="selective "> -->
		<!-- <div class="selective_title">疾病分期</div> -->

		<!-- </div> -->
		<div class="selective ">
			<div class="selective_title"></div>
			<field title="是否手术" type="2" ref="isOperate"></field>
			<field title="是否化疗" type="2" ref="isChemothe" :isEditText="true" areaTips="请填写化疗方案（选填）"></field>
			<field title="靶向治疗" type="2" ref="isTarget" :isEditText="true" areaTips="请填写靶向药物（选填）"></field>
			<field title="免疫治疗" type="2" ref="immuno" :isEditText="true" areaTips="请填写免疫药物（选填）"></field>
			<field title="放射治疗" type="2" ref="radio"></field>
			<field title="其他治疗方式" type="2" ref="otherTreatment" :isEditText="true" areaTips="请填写您的治疗方式（选填）"></field>
		</div>
		<div class="selective ">
			<div class="selective_title">患者的出生日期是？</div>
			<field title="出生日期" type="3" poptype="4" ref="birthday"></field>
		</div>
		<div class="selective ">
			<div class="selective_title">患者的性别是？</div>
			<field title="性别" type="2" first="男" second="女" ref="sex"></field>
		</div>
		<!-- <div class="levelBox">
			<div class="level_title">患者日常活动水平的最佳描述是：</div>
			<level ref="healthState"></level>
		</div> -->
		<div class="selective ">
			<div class="selective_title">患者的称呼：</div>
			<field title="患者称呼" type="1" placeholder="如:小王" ref="name"></field>
		</div>
		<div class="relation">
			<div class="relation_title">我和患者的关系：</div>
			<field title="关系" type="3" poptype="6" ref="relate"></field>
		</div>
		<div class="commitBtn" @click="commit">
			保存并下一步
		</div>
	</div>
</template>

<script>
	import {
		Button,
		Toast
	} from 'vant';
	import field from '@/components/patient/field';
	import level from '@/components/patient/level';
	import patientApi from '@/api/patient/patientApi.js'
	export default {
		components: {
			Button,
			field,
			level,
			[Toast.name]: Toast,
		},
		data() {
			return {
				disable:false,
				data: {
					name: '', //患者称呼
					sex: '', //性别1男 2女
					illType: '', //癌种类型	
				},
				type: this.$route.query.type,
			}
		},
		methods: {
			//子组件调用这个组件
			diseaseClick(illId) {
				// this.$refs.carcuStage.setCarcuStage(illId)
			},
			//过滤空格
			trim(str) {
				return str.replace(/(^\s*)|(\s*$)/g, "");
			},
			//弹出提示
			toast(title) {
				Toast({
					message: title,
					position: 'bottom'
				});
			},
			//判断必填项
			required(data) {
				if (this.trim(data.illType) == "") {
					this.toast("请选择疾病");
					return false;
				} 
				// else if (this.$refs.carcuStage.hidden == true && (data.carcuStage == "" || data.carcuStage == null)) {
				// 	this.toast("请选择分期");
				// 	return false;
				// } else if (this.$refs.isOperate.start == false) {
				// 	this.toast("请选择是否手术");
				// 	return false;
				// } else if (this.$refs.isChemothe.start == false) {
				// 	this.toast("请选择是否化疗");
				// 	return false;
				// } else if (this.$refs.isTarget.start == false) {
				// 	this.toast("请选择是否靶向治疗");
				// 	return false;
				// } else if (this.$refs.immuno.start == false) {
				// 	this.toast("请选择是否免疫治疗");
				// 	return false;
				// } else if (this.$refs.radio.start == false) {
				// 	this.toast("请选择是否放射治疗");
				// 	return false;
				// } else if (this.$refs.otherTreatment.start == false) {
				// 	this.toast("请选择是否其他治疗");
				// 	return false;
				// } else if (this.trim(data.birthday) == "") {
				// 	this.toast("请选择生日");
				// 	return false;
				// } else if (this.$refs.sex.start == false) {
				// 	this.toast("请选择性别");
				// 	return false;
				// } else if (data.healthState == null || data.healthState == "") {
				// 	this.toast("请选择活动水平");
				// 	return false;
				// }
				return true;
			},
			//点击提交保存
			commit() {
				//已经提交过了 不允许重复点击
				if(this.disable){
					this.toast("请勿重复提交");
					return;
				}
				let data = {
					illType: this.$refs.illType.tempName, //疾病
					illId: this.$refs.illType.tempId, //癌种id
					// carcuStage: this.$refs.carcuStage.tempId, //分期
					isOperate: this.$refs.isOperate.getStatus(), //是否手术
					isChemothe: this.$refs.isChemothe.getStatus(), //是否化疗
					chemothe: this.$refs.isChemothe.tempName, //化疗方案
					isTarget: this.$refs.isTarget.getStatus(), //是否靶向治疗
					targetMedicine: this.$refs.isTarget.tempName, //靶向药物
					immuno: this.$refs.immuno.getStatus(), //是否免疫治疗
					immunoMedic: this.$refs.immuno.tempName, //免疫药物
					radio: this.$refs.radio.getStatus(), //是否放射治疗
					isOtherTreatment: this.$refs.otherTreatment.getStatus(), //是否其他治疗
					otherTreatment: this.$refs.otherTreatment.tempName, //治疗方式
					birthday: this.$refs.birthday.tempName, //生日
					sex: this.$refs.sex.getStatus(), //性别
					// healthState: this.$refs.healthState.currentKey, //体力水平
					name: this.$refs.name.tempName, //患者称呼
					relate: this.$refs.relate.tempId, //我和患者的关系
				}
				// if (this.$refs.carcuStage.hidden == true) {
				// 	data.carcuStage = this.$refs.carcuStage.tempId //分期
				// }
				console.log(data)
				if (!this.required(data)) return;
				this.disable = true
				patientApi.save(data).then(rs => {
					//已经提交过一次了
					this.disable=false;
					if (rs.status == 1) {
						if (this.type == 1) {
							this.$router.push({
								path: '/patientList',
							})
						} else {
							this.$router.push({
								path: '/question',
								query: {
									illId:this.$refs.illType.tempId,
									inquiryId: rs.data.id,
									isEdit: 0
								}
							})
						}

					}
				}).catch(err => {
					this.disable = false
				})


			},
		},
		mounted() {

		}
	}
</script>

<style lang="less" scoped="scoped">
	.createPatient {
		background: rgba(247, 247, 247, 1);
		height: auto;

		.necessary {
			.necessary_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: #828284;
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}

		}

		.selective {
			.selective_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: #828284;
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}
		}

		.levelBox {
			.level_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: #828284;
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}
		}

		.relation {
			.relation_title {
				width: 100%;
				height: 44px;
				background: rgba(247, 247, 247, 1);
				font-size: 14px;
				font-weight: 400;
				color: #828284;
				text-align: left;
				line-height: 44px;
				padding-left: 15px;
				box-sizing: border-box;
				// font-weight: bold;
			}
		}

		.commitBtn {
			width: 100%;
			height: 49px;
			background: rgba(0, 96, 194, 1);
			text-align: center;
			line-height: 49px;
			font-size: 17px;
			font-weight: 600;
			color: rgba(255, 255, 255, 1);
			margin-top: 50px;
		}
	}
</style>
