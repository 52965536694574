<template>
	<div class="level">
		<div class="level_title" v-for="(item,index) in data" :key="index">
			<div :class="[currentIndex==index?'active':'','content']"  @click="checked(index,item.key)">
				{{item.value}} 
			</div>
			<div  class="iconCorrect" v-if="currentIndex==index">
				<van-icon name="success" color="#4DC600" size="30" />
			</div>
		
		</div>
	</div>
</template>

<script>
	import patientApi from '@/api/patient/patientApi.js'
	import {
		Icon
	} from 'vant';
	export default {
		components: {
			vanIcon: Icon,
		},
		props: {
			second: {
				type: String,
				default: '否'
			},
		},
		data() {
			return {
				currentIndex:-1,
				currentKey:-1,
				data:[],
				isOk:false,
			}
		},
		methods: {
			//选择当前记录索引
			checked(index,key){
				this.currentIndex=index;
				this.currentKey=key;
			},
			//获取生活水平列表
			getList(){
				patientApi.enumsList().then(rs=>{
					this.data=rs.data.healthStatusList;
					this.isOk=true;
				})
			},
			//赋值
			setVal(val){
				let timer=setInterval(()=>{
					if(this.isOk){
						for(let i=0;i<this.data.length;i++){
							if(this.data[i].key==val){
								this.currentIndex=i;
								break;
							}
						}
						window.clearInterval(timer)
					}
									
				},0)
				
			}
		},

		mounted() {
			this.getList();
		}
	}
</script>

<style scoped="scoped" lang="less">
	.level{
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		box-sizing: border-box;
		background:rgba(255,255,255,1);
		.level_title{
			width: 100%;
			height: auto;
			border-bottom: 1px solid #E7E8ED;
			box-sizing: border-box;
			// background: red;
			
			.content{
				display: inline-block;
				width: 85%;
				font-size:16px;
				padding-bottom: 15px;
				padding-top: 15px;
				font-weight:400;
				color:rgba(130,130,132,1);
				vertical-align: baseline;
				box-sizing: border-box;
				// background: #1989FA;
				
			}	
			.iconCorrect{
				
				display: inline-block;
				width: 15%;
				height: 40px;
				text-align: right;
				// line-height: 60px;
			}
			.active{
				font-weight: bold;
			}
		}
		
	}
</style>
